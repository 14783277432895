import { Injectable, inject, signal } from '@angular/core';
import { DeliverySettingsType } from '@enums/delivery-settings-type.enum';
import { OrderItemType } from '@enums/order-item-type.enum';
import { PaymentType } from '@enums/payment-type.enum';
import { GetSingleOrderPrice } from '@functions/get-single-order-price.function';
import { Addition } from '@interfaces/addition.interface';
import { AdditionsGroups } from '@interfaces/additions-groups.interface';
import { Dish } from '@models/dish.model';
import { Delivery, FinalOrder } from '@models/final-order.model';
import { Order } from '@models/order.model';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, Subject, catchError, takeUntil } from 'rxjs';
import { DatabaseTables } from '../database/enums/database-tables.enum';
import { DBMultiCart } from '../database/interfaces/multi-cart.interface';

/**
 * Represents the initial order object.
 */
const INIT_ORDER = {
  comment: undefined,
  paymentType: undefined,
  phone: undefined,
  usePoints: 0,
  pointsRatio: 100,
  delivery: {
    type: DeliverySettingsType.PERSONAL_PICKUP,
    value: true,
  },
  orders: [],
};

@Injectable({ providedIn: 'root' })
export class CartService {
  /**
   * Represents the database service used by the cart service.
   */
  private readonly dbService = inject(NgxIndexedDBService);

  /**
   * Represents the final order.
   */
  finalOrder = signal<FinalOrder>(new FinalOrder(INIT_ORDER));

  /**
   * Represents the counter for the cart service.
   */
  counter = signal<number>(0);

  /**
   * Represents the list of orders.
   */
  orders = signal<Order[]>([]);

  /**
   * Represents the total price of the cart.
   */
  totalPrice = signal<number>(0);

  /**
   * Represents the minimal price of the cart.
   */
  minimalPrice = signal<number>(0);

  /**
   * Represents the syncing state of the cart service.
   */
  syncing = signal<boolean>(true);

  /**
   * The hostname of the restaurant.
   */
  hostname = signal<string | null>(null);

  /**
   * Represents the payment type for the cart service.
   */
  paymentType = signal<PaymentType | undefined>(undefined);

  /**
   * Represents the signal for the usePoints property.
   * @type {Signal<number>}
   */
  usePoints = signal<number>(0);

  /**
   * The comment property represents a signal for a string value.
   */
  comment = signal<string | undefined>(undefined);
  tpayBank = signal<string | undefined>(undefined);

  /**
   * Represents the phone number associated with the cart.
   */
  phone = signal<string | undefined>(undefined);

  /**
   * The email address associated with the cart service.
   * It can be either a string or null.
   */
  email = signal<string | undefined>(undefined);
  orderItemType = signal<OrderItemType>(OrderItemType.DISH);

  /**
   * The user ID associated with the cart service.
   * It represents the ID of the user who owns the cart.
   */
  userId = signal<number | undefined>(undefined);

  /**
   * Represents the delivery methods available for the cart service.
   */
  deliveryMethods = signal<DeliverySettingsType[] | undefined>(undefined);

  /**
   * Represents the payment methods available for the cart service.
   */
  paymentMethods = signal<PaymentType[] | undefined>(undefined);

  /**
   * The name of the restaurant associated with the cart service.
   * @remarks
   * This property holds the name of the restaurant that the cart service is currently associated with.
   * It can be either a string or null.
   */
  restaurantName = signal<string | null>(null);

  /**
   * Represents the delivery information.
   */
  delivery = signal<Delivery>({
    type: undefined,
    value: undefined,
  });

  /**
   * Represents the subscriptions for the cart service.
   */
  destroyed$ = new Subject<void>();

  /**
   * Updates the counter with the total quantity of orders in the final order.
   */
  updateCounter(): void {
    this.counter.set(this.finalOrder().orders.reduce((sum, order) => sum + order.quantity!, 0));
  }

  /**
   * Updates the orders in the cart service.
   * @param orders - An array of Order objects representing the updated orders.
   */
  updateOrders(orders: Order[]): void {
    this.orders.set(orders);
  }

  /**
   * Updates the total price of the cart by calculating the sum of dish prices and addition prices.
   * @param additions - An array of Addition objects representing the selected additions.
   */
  updateTotalPrice(): void {
    const totalPrice = this.finalOrder().orders.reduce((sum, order) => sum + GetSingleOrderPrice(order), 0);
    this.totalPrice.set(totalPrice);
  }

  /**
   * Updates the payment type for the final order.
   *
   * @param type - The new payment type to be set.
   */
  updatePaymentType(type: PaymentType): void {
    this.finalOrder.update((finalOrder: FinalOrder) => {
      finalOrder.paymentType = type;
      this.paymentType.set(type);
      return finalOrder;
    });

    this.updateDb();
  }

  /**
   * Updates the usePoints property of the finalOrder and updates the database.
   * @param points - The number of points to be used.
   */
  updateUsePoints(points: number): void {
    this.finalOrder.update((finalOrder: FinalOrder) => {
      finalOrder.usePoints = points;
      this.usePoints.set(points);
      return finalOrder;
    });

    this.updateDb();
  }

  /**
   * Updates the comment for the final order.
   *
   * @param comment - The new comment for the final order.
   */
  updateComment(comment: string | undefined): void {
    this.finalOrder.update((finalOrder: FinalOrder) => {
      finalOrder.comment = comment;
      this.comment.set(comment);
      return finalOrder;
    });

    this.updateDb();
  }

  /**
   * Updates the phone number for the final order and delivery settings.
   * @param phone - The new phone number to be updated.
   */
  updatePhone(phone: string | undefined): void {
    this.finalOrder.update((finalOrder: FinalOrder) => {
      finalOrder.phone = phone;
      if (this.delivery().type === DeliverySettingsType.ADDRESS) {
        this.delivery.update((delivery: Delivery) => {
          if (delivery.value) {
            delivery.value.phone = phone;
          }
          return delivery;
        });
      }
      this.phone.set(phone);
      return finalOrder;
    });

    this.updateDb();
  }

  /**
   * Updates the delivery information for the final order.
   *
   * @param delivery - The new delivery information.
   */
  updateDelivery(delivery: Delivery): void {
    this.finalOrder.update((finalOrder: FinalOrder) => {
      finalOrder.delivery!.type = delivery.type;
      finalOrder.delivery!.value = delivery.value;
      this.delivery.set(delivery);
      return finalOrder;
    });

    this.updateDb();
  }

  /**
   * Updates the signals for the cart.
   * This method updates the counter, total price, and orders in the cart.
   */
  updateSignals(): void {
    // this.updateOrders(this.finalOrder().orders);
    this.updateTotalPrice();
    this.updateCounter();
  }

  /**
   * Updates the database with the final order.
   */
  updateDb(): void {
    if (this.hostname() && this.userId()) {
      this.dbManageMultiCart(this.hostname()!, this.finalOrder(), this.userId()!);
    }
  }

  /**
   * Finds the index of an order in the final order list based on the dish and additions.
   * @param dish - The dish to search for.
   * @param additions - The list of additions to search for.
   * @returns The index of the order if found, otherwise -1.
   */
  findOrderIndex(dish: Dish, additions: Addition[]): number {
    return this.finalOrder().orders.findIndex(
      (order: Order) =>
        order.dish?.id === dish.id &&
        order.additions?.length === additions.length &&
        order.additions?.every((addition, i) => addition.id === additions[i].id)
    );
  }

  /**
   * Adds a dish to the cart with the specified additions, addition groups, and quantity.
   * @param dish - The dish to add to the cart.
   * @param additions - The list of additions for the dish.
   * @param additionsGroups - The list of addition groups for the dish.
   * @param quantity - The quantity of the dish to add.
   */
  addToCart(
    dish: Dish,
    additions: Addition[],
    additions_groups: AdditionsGroups[],
    quantity: number,
    restaurantName: string | null,
    minimalPrice: number,
    orderItemType: OrderItemType,
    email?: string,
    userId?: number,
    deliveryMethods?: DeliverySettingsType[],
    paymentMethods?: PaymentType[]
  ): void {
    const index = this.findOrderIndex(dish, additions);

    const orderItem: Order = new Order({
      id: dish.id,
      dish: Object.assign(dish, { additions_groups }),
      quantity,
      additions,
      discount: '0',
      type: orderItemType,
    });

    switch (index) {
      case -1:
        this.restaurantName.set(restaurantName);
        this.minimalPrice.set(minimalPrice);
        this.orderItemType.set(orderItemType);
        this.email.set(email);
        this.userId.set(userId);
        this.deliveryMethods.set(deliveryMethods);
        this.paymentMethods.set(paymentMethods);
        this.addNewOrder(orderItem);
        break;
      default:
        const existingOrder = this.finalOrder().orders[index];
        const isEveryAdditionEqual = existingOrder?.additions?.every((addition, i) => addition.id === additions[i].id);
        const hasSameSize = existingOrder?.additions?.length === additions.length;

        if (hasSameSize && isEveryAdditionEqual) {
          this.updateOrderItemQuantity(index, quantity);
        } else {
          this.addNewOrder(orderItem);
        }
        break;
    }

    this.updateSignals();
    this.updateDb();
  }

  /**
   * Adds a new order item to the final order.
   *
   * @param orderItem - The order item to be added.
   */
  private addNewOrder(orderItem: Order): void {
    this.finalOrder.update((finalOrder: FinalOrder) => {
      finalOrder.orders.push(orderItem);
      return finalOrder;
    });
  }

  /**
   * Updates the quantity of an order item at the specified index in the final order.
   * @param index - The index of the order item to update.
   * @param quantity - The quantity to add to the order item.
   */
  private updateOrderItemQuantity(index: number, quantity: number): void {
    this.finalOrder.update((finalOrder: FinalOrder) => {
      finalOrder.orders[index].quantity! += quantity;
      return finalOrder;
    });
  }

  /**
   * Removes a dish from the cart.
   * @param dish - The dish to be removed from the cart.
   */
  removeFromCart(order: Order): void {
    const index = this.findOrderIndex(order.dish!, order.additions!);

    if (index !== -1) {
      this.finalOrder.update((finalOrder: FinalOrder) => {
        if (finalOrder.orders[index].quantity! > 1) {
          finalOrder.orders[index].quantity! -= 1;
        } else {
          finalOrder.orders.splice(index, 1);
        }
        return finalOrder;
      });

      this.updateSignals();
      this.updateDb();
    }
  }

  /**
   * Increases the quantity of the specified order in the cart.
   *
   * @param order - The order to increase the quantity for.
   */
  increaseQuantity(order: Order): void {
    this.addToCart(
      order.dish!,
      order.additions!,
      order.dish!.additions_groups!,
      1,
      this.restaurantName(),
      this.minimalPrice(),
      this.orderItemType(),
      this.email(),
      this.userId()
    );
  }

  /**
   * Decreases the quantity of the specified order in the cart.
   * @param order - The order to decrease the quantity for.
   */
  decreaseQuantity(order: Order): void {
    this.removeFromCart(order);
  }

  /**
   * Synchronizes the cart data with the database for the specified hostname.
   * @param hostname - The hostname for which to synchronize the cart data.
   */
  dbSync(hostname: string, userId: number): void {
    this.dbGetMultiCart(hostname, userId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (cart: DBMultiCart) => {
          if (cart) {
            this.finalOrder.set(new FinalOrder(cart.cart));

            const { restaurantName, minimalPrice, email, deliveryMethods, paymentMethods } = cart;
            this.restaurantName.set(restaurantName);
            this.minimalPrice.set(minimalPrice);
            this.email.set(email?.includes('@') ? email : undefined);
            this.userId.set(userId);
            this.deliveryMethods.set(deliveryMethods);
            this.paymentMethods.set(paymentMethods);

            const { paymentType, comment, phone, delivery, usePoints, orders } = cart.cart;
            this.paymentType.set(paymentType);
            this.usePoints.set(usePoints);
            this.comment.set(comment);
            this.phone.set(phone);
            this.orders.set(orders);

            const { type, value } = delivery;
            this.delivery.set({ type, value });
          } else {
            const finalOrder = this.finalOrder();
            finalOrder.orders = [];
            this.finalOrder.set(new FinalOrder(finalOrder));
          }

          this.updateSignals();
          this.syncing.set(false);
        },
      });
  }

  /**
   * Manages the multi-cart in the database.
   * If a cart with the given hostname already exists, it updates the existing cart.
   * Otherwise, it adds a new cart to the database.
   * @param hostname - The hostname of the cart.
   * @param cart - The final order cart to be managed.
   */
  dbManageMultiCart(hostname: string, cart: FinalOrder, userId: number): void {
    this.dbGetMultiCart(hostname, userId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (multiCart: DBMultiCart) => {
          if (multiCart) {
            multiCart.cart = cart;
            this.dbUpdateMultiCart(multiCart);
          } else {
            this.dbAddMultiCart(hostname, cart);
          }
        },
      });
  }

  /**
   * Updates the email associated with the cart in the database.
   * If the cart exists, the email will be updated with the provided value.
   *
   * @param hostname - The hostname of the cart.
   * @param email - The new email to be associated with the cart.
   */
  dbUpdateEmail(email?: string): void {
    this.dbGetMultiCart(this.hostname()!, this.userId()!)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (multiCart: DBMultiCart) => {
          if (multiCart) {
            multiCart.email = email;
            this.email.set(email);
            this.dbUpdateMultiCart(multiCart);
          }
        },
      });
  }

  /**
   * Updates the multi-cart in the database.
   *
   * @param multiCart - The multi-cart object to be updated.
   */
  dbUpdateMultiCart(multiCart: DBMultiCart): void {
    this.dbService.update(DatabaseTables.MULTICART, multiCart).pipe(takeUntil(this.destroyed$)).subscribe();
  }

  /**
   * Deletes a multi-cart from the database.
   *
   * @param multiCart - The multi-cart to be deleted.
   */
  dbDeleteMultiCart(): void {
    this.dbService
      .delete(DatabaseTables.MULTICART, `${this.hostname()}-${this.userId()}`)
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  /**
   * Adds a multi-cart to the database.
   *
   * @param hostname - The hostname associated with the multi-cart.
   * @param cart - The multi-cart to be added.
   */
  dbAddMultiCart(hostname: string, cart: FinalOrder, orderAgain = false): void {
    this.dbService
      .add(DatabaseTables.MULTICART, {
        hostname: `${hostname}-${this.userId()}`,
        cart,
        restaurantName: this.restaurantName(),
        minimalPrice: this.minimalPrice(),
        email: this.email(),
        deliveryMethods: this.deliveryMethods(),
        paymentMethods: this.paymentMethods(),
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        error: () => {
          if (orderAgain) {
            this.dbDeleteMultiCart();
            this.dbAddMultiCart(hostname, cart);
          }
        },
      });
  }

  /**
   * Retrieves multiple carts from the database based on the provided hostname.
   * @param hostname - The hostname to filter the carts by.
   * @returns An Observable that emits an array of DBMultiCart objects.
   */
  dbGetMultiCart(hostname: string, userId: number): Observable<any> {
    return this.dbService
      .getByKey<DBMultiCart>(DatabaseTables.MULTICART, `${hostname}-${userId}`)
      .pipe(catchError(() => []));
  }

  /**
   * Resets the cart by setting the final order to the initial order and updating the signals.
   */
  reset(): void {
    // TODO - unused method
    this.finalOrder.set(new FinalOrder(INIT_ORDER));
    this.restaurantName.set(null);
    this.syncing.set(true);
    this.updateSignals();
  }

  /**
   * Unsubscribes from the cart service.
   */
  unsubscribe(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
